import Container from 'react-bootstrap/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSection } from '@fortawesome/free-solid-svg-icons'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { faBuildingColumns } from '@fortawesome/free-solid-svg-icons'
import { faBuilding } from '@fortawesome/free-solid-svg-icons'
import { faBuildingCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { faLightbulb } from '@fortawesome/free-solid-svg-icons'
import { faMoneyBillTrendUp } from '@fortawesome/free-solid-svg-icons'

function WhyImportant() {
	return (
		<section className='whyImportant'>
			<h1>Dlaczego są tak ważne?</h1>
			<div className='underline'></div>
			<Container>
				<div className='WhyImportant_list'>
					<div className='card'>
						<FontAwesomeIcon className='faIcons' icon={faBuildingCircleCheck} />
						<div className='card-body'>
							<p className='card-text'>Umożliwiają ocenę efektywności energetycznej budynków</p>
						</div>
					</div>
					<div className='card'>
						<div className='d-flex justify-content-evenly'>
							<FontAwesomeIcon className='faIcons' icon={faHome} />
							<FontAwesomeIcon className='faIcons' icon={faBuildingColumns} />
							<FontAwesomeIcon className='faIcons' icon={faBuilding} />
						</div>
						<div className='card-body'>
							<p className='card-text'>Umożliwiają porównanie budynków</p>
						</div>
					</div>
					<div className='card'>
						<FontAwesomeIcon className='faIcons' icon={faLightbulb} />
						<div className='card-body'>
							<p className='card-text'>Pomagają w oszczędzaniu energii</p>
						</div>
					</div>
					<div className='card'>
						<FontAwesomeIcon className='faIcons' icon={faMoneyBillTrendUp} />
						<div className='card-body'>
							<p className='card-text'>Mogą wpłynąć na wartość nieruchomości</p>
						</div>
					</div>
					<div className='card'>
						<FontAwesomeIcon className='faIcons' icon={faSection} />
						<div className='card-body'>
							<p className='card-text'>Są wymagane przez prawo</p>
						</div>
					</div>
				</div>
				<div className='d-flex justify-content-center align-items-center'>
					<button className='describe_button_buy'>
						<a className='describe_btn' href='/Cena'>
							Zamów teraz
						</a>
					</button>
				</div>
			</Container>
		</section>
	)
}

export default WhyImportant
