import Container from 'react-bootstrap/Container'
import React, { useState, useRef } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Row from 'react-bootstrap/Row'

import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer, toast } from 'react-toastify'

import emailjs from '@emailjs/browser'

function Form_Validate(props) {
	const [validated, setValidated] = useState(false)

	const [selectedOption, setSelectedOption] = useState({
		Number_of_storeys: '',
		exposure_of_the_property: '',
		type_of_heating: '',
		type_of_heating_water: '',
		type_of_heaters: '',
		type_of_ventilation: '',
		window_measurement_material: '',
		number_of_panes_in_window: '',
		world_direction: '',
		types_of_pipes: '',
	})

	const [data, setData] = useState({
		OutDor: '',
		otherInformation: '',
		roofInformation: '',
		CeilingInformation: '',
		floorInformation: '',
		CelingUnder: '',
	})

	const [showInputs, setShowInputs] = useState(false)

	const form = useRef()

	const [checkboxes, setCheckboxes] = useState({
		checkboxVat: false,
		checkboxService: false,
		checkboxGroundFlorr: false,
		checkboxlastfloor: false,
		checkboxWallOutside: false,
		checkboxthermodynamic: false,
		checkboxStatement: false,
		checkboxregulations: false,
		checkboxOneFamilyHouse: false,
		checkboxMultiApartment: false,
		checkboxBusinessPremises: false,
	})

	const handleSelectChange = event => {
		const { name, value } = event.target
		setSelectedOption(prevState => ({ ...prevState, [name]: value }))
	}

	const handeInputData = event => {
		setData({
			...data,
			[event.target.name]: event.target.value,
		})
	}

	const handleCheckboxChange = event => {
		const { name, checked } = event.target
		if (checked) {
			setCheckboxes(prevState => ({ ...prevState, [name]: true }))
		} else {
			setCheckboxes(prevState => ({ ...prevState, [name]: false }))
		}
	}
	const handleClickChecbox = event => {
		const { name, checked } = event.target
		if (checked) {
			setCheckboxes(prevState => ({ ...prevState, [name]: true }))
		} else {
			setCheckboxes(prevState => ({ ...prevState, [name]: false }))
		}
		setShowInputs(!showInputs)
	}

	const notify = (msg, type) =>
		toast[type](msg, {
			position: 'top-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: 'light',
		})

	const sendEmail = e => {
		e.preventDefault()
		const formSelector = e.currentTarget

		if (formSelector.checkValidity() === false) {
			e.preventDefault()
			e.stopPropagation()
			setValidated(true)
			notify('Błędnie wypełniony formularz', 'warning')
		} else {
			emailjs.sendForm('service_axia8v3', 'template_zwqnltv', form.current, 'v1IIliYzAsKWHeTR8').then(
				result => {
					console.log(result.text)

					let clearCheckboxes = { ...checkboxes }
					for (let index in clearCheckboxes) {
						clearCheckboxes[index] = false
					}

					setCheckboxes(clearCheckboxes)

					/* clear all data */
					const clearData = { ...data }
					for (let [index] of Object.entries(clearData)) {
						clearData[index] = ''
					}
					setData({ ...clearData })

					/* clear all select data */
					const clearSelectData = { ...selectedOption }
					for (let [index] of Object.entries(clearSelectData)) {
						clearSelectData[index] = ''
					}
					setSelectedOption({ ...clearSelectData })

					formSelector.reset()

					setValidated(false)

					notify('formularz wysłany', 'success')
				},
				error => {
					console.log(error.text)
					notify('Błąd wysyłki formularza', 'error')
				}
			)
		}
	}

	return (
		<section className='form_validate' style={{ display: props.isDisplay ? 'block' : 'none' }} id='form_validate_id'>
			<h3 className='text-center mt-3'>Wprowadź wymagane dane</h3>
			<ToastContainer />
			<Container>
				<div className='Personal_data'>
					<p className='form_data_title'>Dane personalne:</p>
					<Form noValidate ref={form} validated={validated} onSubmit={sendEmail}>
						<Row className='mb-3'>
							<Form.Group as={Col} md='6' controlId='validationCustom01'>
								<Form.Label className='form_data'>Imię</Form.Label>
								<Form.Control required type='text' name='name' min='3' max='13' aria-describedby='nameHelpBlock' />
								<Form.Text id='nameHelpBlock' muted>
									Imię musi składać się z 3-13 liter
								</Form.Text>
								<Form.Control.Feedback>Pole wypełnione prawidłowo</Form.Control.Feedback>
							</Form.Group>
							<Form.Group as={Col} md='6' controlId='validationCustom02'>
								<Form.Label className='form_data'>Nazwisko</Form.Label>
								<Form.Control
									required
									type='text'
									name='surname'
									min='2'
									max='24'
									aria-describedby='sureNameHelpBlock'
								/>
								<Form.Text id='sureNameHelpBlock' muted>
									Nazwisko musi składać się z 2-24 liter
								</Form.Text>
								<Form.Control.Feedback>Pole wypełnione prawidłowo</Form.Control.Feedback>
							</Form.Group>
						</Row>
						<Row className='mb-3'>
							<Form.Group as={Col} md='6' controlId='validationCustom01'>
								<Form.Label className='form_data'>E-mail</Form.Label>
								<Form.Control required type='text' name='email' aria-describedby='emailHelpBlock' />
								<Form.Text id='emailHelpBlock' muted>
									Przykładowy email: email@gmail.com
								</Form.Text>
								<Form.Control.Feedback>Pole wypełnione prawidłowo</Form.Control.Feedback>
							</Form.Group>
							<Form.Group as={Col} md='6' controlId='validationCustom02'>
								<Form.Label className='form_data'>Numer telefonu</Form.Label>
								<Form.Control required type='tel' name='phone' min='9' max='9' aria-describedby='phoneHelpBlock' />
								<Form.Text id='phoneHelpBlock' muted>
									Numer telefonu musi zawierać 9 cyfr
								</Form.Text>
								<Form.Control.Feedback>Pole wypełnione prawidłowo</Form.Control.Feedback>
							</Form.Group>
						</Row>
						<p>CZY CHCIAŁBYŚ, ABY WYSTAWIONO FAKTURĘ VAT?</p>
						{['checkbox'].map(type => (
							<div key={`default-${type}`} className='mb-3'>
								<Form.Check
									type={type}
									name='checkboxVat'
									id='checkboxVat'
									label={`Proszę o wystawienie faktury VAT `}
									checked={checkboxes.checkboxVat}
									onChange={handleClickChecbox}
								/>

								<Form.Check
									type={type}
									name='checkboxService'
									label={`Proszę o wysłanie oryginału świadectwa. Zaznaczenie tej opcji wiąże się z dodatkową opłatą 40 zł brutto. Wysyłka następuje w ciągu 7 dni roboczych.`}
									id='checkboxService'
									checked={checkboxes.checkboxService}
									onChange={handleCheckboxChange}
								/>
							</div>
						))}
						<p className='form_data_title'>Dane adresowe:</p>
						<Row className='mb-3'>
							<Form.Group as={Col} md='6' controlId='validationCustom03'>
								<Form.Label className='form_data'>Ulica</Form.Label>
								<Form.Control type='text' name='street' required />
								<Form.Control.Feedback type='invalid'>Proszę wpisać prawidłową ulicę</Form.Control.Feedback>
							</Form.Group>
							<Form.Group as={Col} md='6' controlId='validationCustom06'>
								<Form.Label className='form_data'>Województwo</Form.Label>
								<Form.Control type='text' name='Province' required />
								<Form.Control.Feedback type='invalid'>Proszę wpisać prawidłowe Województwo</Form.Control.Feedback>
							</Form.Group>
							<Form.Group as={Col} md='6' controlId='validationCustom06'>
								<Form.Label className='form_data'>Powiat</Form.Label>
								<Form.Control type='text' name='District' required />
								<Form.Control.Feedback type='invalid'>Proszę wpisać prawidłowy Powiat</Form.Control.Feedback>
							</Form.Group>
							<Form.Group as={Col} md='6' controlId='validationCustom06'>
								<Form.Label className='form_data'>Gmina</Form.Label>
								<Form.Control type='text' name='Municipality' required />
								<Form.Control.Feedback type='invalid'>Proszę wpisać prawidłową Gminę</Form.Control.Feedback>
							</Form.Group>
							<Form.Group as={Col} md='4' controlId='validationCustom04'>
								<Form.Label className='form_data'>Numer bloku</Form.Label>
								<Form.Control type='text' name='block_number' required />
								<Form.Control.Feedback type='invalid'>Proszę wpisać prawidłowy numer bloku</Form.Control.Feedback>
							</Form.Group>
							<Form.Group as={Col} md='4' controlId='validationCustom05'>
								<Form.Label className='form_data'>Numer lokalu</Form.Label>
								<Form.Control type='text' name='premises_number' required />
								<Form.Control.Feedback type='invalid'>Proszę wpisać prawidłowy numer lokalu</Form.Control.Feedback>
							</Form.Group>
							<Form.Group as={Col} md='4' controlId='validationCustom05'>
								<Form.Label className='form_data'>Numer domu</Form.Label>
								<Form.Control type='text' name='house_number' required />
								<Form.Control.Feedback type='invalid'>Proszę wpisać prawidłowy numer domu</Form.Control.Feedback>
							</Form.Group>
						</Row>
						<Row className='mb-3'>
							<Form.Group as={Col} md='6' controlId='validationCustom01'>
								<Form.Label className='form_data'>Miasto</Form.Label>
								<Form.Control required type='text' name='city' />
								<Form.Control.Feedback>Pole wypełnione prawidłowo</Form.Control.Feedback>
							</Form.Group>
							<Form.Group as={Col} md='6' controlId='validationCustom02'>
								<Form.Label className='form_data'>Kod Pocztowy</Form.Label>
								<Form.Control type='text' required pattern='^\d{2}-\d{3}$' name='postcode' />
								<Form.Control.Feedback>Pole wypełnione prawidłowo</Form.Control.Feedback>
							</Form.Group>
							{showInputs && (
								<>
									<Form.Group as={Col} md='6' controlId='validationCustom01'>
										<Form.Label className='form_data'>Nip</Form.Label>
										<Form.Control required type='text' name='nip' aria-describedby='nipHelpBlock' />
										<Form.Text id='nipHelpBlock' muted>
											NIP musi zawierać od 10 do 12 cyfr
										</Form.Text>
										<Form.Control.Feedback>Pole wypełnione prawidłowo</Form.Control.Feedback>
									</Form.Group>
									<Form.Group as={Col} md='6' controlId='validationCustom02'>
										<Form.Label className='form_data'>Nazwa firmy</Form.Label>
										<Form.Control type='text' required name='Company_name' placeholder='Nazwa firmy' />
										<Form.Control.Feedback>Pole wypełnione prawidłowo</Form.Control.Feedback>
									</Form.Group>
								</>
							)}
						</Row>
						<p className='form_data_title'>Podstawowe dane nieruchomości:</p>
						<Row className='mb-3'>
							{['checkbox'].map(type => (
								<div key={`default-${type}`} className='mb-3 form_data'>
									<Form.Check
										type={type}
										name='checkboxMultiApartment'
										id='checkboxMultiApartment'
										label={`Budynek wielomieszkaniowy`}
										checked={checkboxes.checkboxMultiApartment}
										ref={props.ref1}
										onChange={handleCheckboxChange}
									/>
									<Form.Check
										type={type}
										name='checkboxBusinessPremises'
										label={`Czy lokal przeznaczony jest na inna funkcję niż  meiszkalną (np. usługowy).`}
										id='checkboxBusinessPremises'
										checked={checkboxes.checkboxBusinessPremises}
										ref={props.ref2}
										onChange={handleCheckboxChange}
									/>
									<Form.Check
										type={type}
										name='checkboxOneFamilyHouse'
										label={`Dom jedno/wielorodzinny`}
										id='checkboxOneFamilyHouse'
										checked={checkboxes.checkboxOneFamilyHouse}
										ref={props.ref3}
										onChange={handleCheckboxChange}
									/>
								</div>
							))}
							<div className='mb-3 form_data'>
								<Form.Check // prettier-ignore
									id="radioNewBuild-1"
									type='radio'
									name='radioNewBuild'
									value='Nowy_budynek'
									label='Nowy budynek'
								/>
								<Form.Check // prettier-ignore
									id="radioNewBuild-2"
									type='radio'
									name='radioNewBuild'
									value='Istniejący_budynek'
									label='Istniejący budynek'
								/>
							</div>

							<Form.Group as={Col} md='4' controlId='validationCustom05'>
								<Form.Label className='form_data'>Całkowita powierzchnia użytkowa(m):</Form.Label>
								<Form.Control type='number' name='heated_area' required />
								<Form.Control.Feedback type='invalid'>
									Proszę wpisać prawidłową powierzchnie użytkową
								</Form.Control.Feedback>
							</Form.Group>
							<Form.Group as={Col} md='4' controlId='validationCustom06'>
								<Form.Label className='form_data'>Długość przegród zewnętrznych (m):</Form.Label>
								<Form.Control
									type='number'
									name='Length_of_partitions'
									required
									aria-describedby='Length_of_partitions_HelpBlock'
								/>
								<Form.Text id='Length_of_partitions_HelpBlock' muted>
									Przegrody zewnętrzne to takie które mają kontakt z zewnętrzem
								</Form.Text>
								<Form.Control.Feedback type='invalid'>
									Proszę wpisać prawidłową powierzchnie użytkową
								</Form.Control.Feedback>
							</Form.Group>
							<Form.Group as={Col} md='4' controlId='validationCustom04'>
								<Form.Label className='form_data'>Wysokość pomieszczeń (m):</Form.Label>
								<Form.Control type='number' name='room_height' required />
								<Form.Control.Feedback type='invalid'>
									Proszę wpisać prawidłową wysokość pomieszczeń
								</Form.Control.Feedback>
							</Form.Group>
							<Form.Group as={Col} md='4' controlId='validationCustom05' className='mt-2'>
								<Form.Label className='form_data'>Rok oddania do użytkowania:</Form.Label>
								<Form.Control type='number' name='year_usage' required />
								<Form.Control.Feedback type='invalid'>
									Proszę wpisać prawidłowy rok oddania użytkowania
								</Form.Control.Feedback>
							</Form.Group>
							<Form.Group as={Col} md='4' controlId='validationCustom01'>
								<Form.Label className='form_data'>Kierunek świata dla ściany frontowej:</Form.Label>
								<Form.Select
									required
									as='select'
									type='select'
									id='world_direction'
									name='world_direction'
									value={selectedOption.world_direction}
									onChange={handleSelectChange}
								>
									<option value=''>Wybierz jedną z opcji</option>
									<option value='E'>E</option>
									<option value='W'>W</option>
									<option value='N'>N</option>
									<option value='S'>S</option>
								</Form.Select>
								<Form.Control.Feedback type='invalid'>Proszę wybrać jedną z opcji</Form.Control.Feedback>
							</Form.Group>

							<Form.Group as={Col} md='4' controlId='validationCustom06' className='mt-2'>
								<Form.Label className='form_data'>Nośnik energi dla CO:</Form.Label>
								<Form.Control
									type='text'
									name='Energy_carrier_CO'
									required
									aria-describedby='Energy_carrier_CO_HelpBlock'
								/>
								<Form.Text id='Energy_carrier_CO_HelpBlock' muted>
									<p>Przykładowe nośniki energi CO:</p>
									<ul>
										<li>Prąd</li>
										<li>Gaz</li>
										<li>Węgiel</li>
										<li>Słońce</li>
										<li>Wiatr</li>
									</ul>
								</Form.Text>
							</Form.Group>
						</Row>
						<Row className='mb-3'>
							<Form.Group as={Col} md='4' controlId='validationCustom06'>
								<Form.Label className='form_data'>Nośnik energi dla CWU:</Form.Label>
								<Form.Control
									type='text'
									name='Energy_carrier_CWU'
									required
									aria-describedby='Energy_carrier_CWU_HelpBlock'
								/>
								<Form.Text id='Energy_carrier_CWU_HelpBlock' muted>
									<p>Przykładowe nośniki energi CWU:</p>
									<ul>
										<li>Prąd</li>
										<li>Gaz</li>
										<li>Węgiel</li>
										<li>Słońce</li>
										<li>Wiatr</li>
									</ul>
								</Form.Text>
							</Form.Group>
							<Form.Group as={Col} md='4' controlId='validationCustom06'>
								<Form.Label className='form_data'>Ściana zewnetrzna:</Form.Label>
								<Form.Control type='text' name='Exterior_wall' aria-describedby='Exterior_wall_HelpBlock' required />
								<Form.Text id='Exterior_wall_HelpBlock' muted>
									<p>
										Ściana zewnętrzna to taka która sąsiaduje z zewnetrzem:
										<p>W tym miejscu należy podać:</p>
										<ul>
											<li>Wymiar/Wymiary sciany</li>
											<li>Typ ściany</li>
											<li>Budowę ściany</li>
											<li>Grubość ściany</li>
										</ul>
									</p>
								</Form.Text>
							</Form.Group>
							<Form.Group as={Col} md='4' controlId='validationCustom01'>
								<Form.Label className='form_data'>Liczba kondygnacji nieruchomości:</Form.Label>
								<Form.Select
									required
									as='select'
									type='select'
									id='Number_of_storeys'
									name='Number_of_storeys'
									value={selectedOption.Number_of_storeys}
									onChange={handleSelectChange}
								>
									<option value=''>Wybierz</option>
									<option value='Nieruchomośc jedno-kondygnacyjna'>Nieruchomośc jedno-kondygnacyjna</option>
									<option value='Nieruchomośc dwu-kondygnacyjna'>Nieruchomośc dwu-kondygnacyjna</option>
									<option value='Nieruchomośc trzy-kondygnacyjna'>Nieruchomośc trzy-kondygnacyjna</option>
									<option value='Nieruchomośc cztero-kondygnacyjna'>Nieruchomośc cztero-kondygnacyjna</option>
								</Form.Select>
							</Form.Group>
							<Form.Group as={Col} md='6' controlId='validationCustom01'>
								<Form.Label className='form_data'>
									Na której kondygnacji znajduje się obliczana nieruchomość:
								</Form.Label>
								<Form.Select
									aria-label='Default select example'
									id='calculated_floor'
									name='calculated_floor'
									value={selectedOption.calculated_floor}
									onChange={handleSelectChange}
									required
								>
									<option value=''>Wybierz kondygnację</option>
									<option value='Na pierwszej kondygnacji'>Na pierwszej kondygnacji</option>
									<option value='Na drugiej kondygnacji'>Na drugiej kondygnacji</option>
									<option value='Na trzeciej kondygnacji'>Na trzeciej kondygnacji</option>
									<option value='Na czwartej kondygnacji'>Na czwartej kondygnacji</option>
								</Form.Select>
							</Form.Group>
							<Form.Group as={Col} md='6' controlId='validationCustom02'>
								<Form.Label className='form_data'>Ekspozycja nieruchomości:</Form.Label>
								<Form.Select
									aria-label='Default select example'
									id='exposure_of_the_property'
									name='exposure_of_the_property'
									value={selectedOption.exposure_of_the_property}
									onChange={handleSelectChange}
									required
								>
									<option value=''>Ekspozycja nieruchomości</option>
									<option value='Nieruchomość jednostronna'>Nieruchomość jednostronna</option>
									<option value='Nieruchomość dwustronna'>Nieruchomość dwustronna</option>
									<option value='Nieruchomość trzystronna'>Nieruchomość trzystronna</option>
									<option value='Nieruchomość czterostronna'>Nieruchomość czterostronna</option>
								</Form.Select>
							</Form.Group>
						</Row>
						<Row className='mb-3'>
							<Form.Group as={Col} md='6' controlId='validationCustom01'>
								{['checkbox'].map(type => (
									<div key={`inline-${type}`} className='form_data'>
										<Form.Check
											inline
											label='Zaznacz, jeśli mieszkanie znajduje się na parterze.'
											name='checkboxGroundFlorr'
											type={type}
											checked={checkboxes.checkboxGroundFlorr}
											onChange={handleCheckboxChange}
											id={`inline-${type}-1`}
										/>
										<Form.Check
											inline
											label='Zaznacz, jeśli mieszkanie znajduje się na ostatnim piętrze.'
											name='checkboxlastfloor'
											checked={checkboxes.checkboxlastfloor}
											onChange={handleCheckboxChange}
											type={type}
											id={`inline-${type}-2`}
										/>
									</div>
								))}
							</Form.Group>
							<Form.Group as={Col} md='12' controlId='validationCustom02'>
								{['checkbox'].map(type => (
									<div>
										<Form.Check
											inline
											label='Zaznacz jeśli budynek, w którym znajduje się mieszkanie przechodził termomodernizację.'
											name='checkboxthermodynamic'
											checked={checkboxes.checkboxthermodynamic}
											onChange={handleCheckboxChange}
											type={type}
											id={`inline-${type}-2`}
										/>
									</div>
								))}
							</Form.Group>
						</Row>
						<p className='form_data_title'>Pozostałe dane do wykonania świadectwa:</p>

						<Row className='mb-3'>
							<Form.Group as={Col} md='6' controlId='validationCustom01'>
								<Form.Label className='form_data'>Rodzaj ogrzewania:</Form.Label>
								<Form.Select
									aria-label='Default select example'
									name='type_of_heating'
									id='type_of_heating'
									value={selectedOption.type_of_heating}
									onChange={handleSelectChange}
									required
								>
									<option value=''>Wybierz rodzaj ogrzewania</option>
									<option value='Podłogowe'>Podłogowe</option>
									<option value='Kaloryfery'>Kaloryfery</option>
									<option value='Nadmuch'>Nadmuch</option>
								</Form.Select>
							</Form.Group>
							{/* { selectedOption.type_of_heating } */}
							{['Podłogowe', 'Kaloryfery', 'Nadmuch'].includes(selectedOption.type_of_heating)
								? (
									<div className='mb-3 form_data'>
										<p>Czy wszytko jest sterowane?</p>
										<Form.Check
											type='radio'
											name='heating_electronically_controlled'
											id='heating_electronically_controlled-1'
											label={`Tak`}
											value='Tak'
										/>
										<Form.Check
											type='radio'
											name='heating_electronically_controlled'
											label={`Nie`}
											id='heating_electronically_controlled-2'
											value='Nie'
										/>
									</div>
								  )
								: null}
							<Form.Group as={Col} md='6' controlId='validationCustom02'>
								<Form.Label className='form_data'>Rodzaj ogrzewania ciepłej wody:</Form.Label>
								<Form.Select
									aria-label='Default select example'
									name='type_of_heating_water'
									id='type_of_heating_water'
									value={selectedOption.type_of_heating_water}
									onChange={handleSelectChange}
									required
								>
									<option value=''>Wybierz rodzaj</option>
									<option value='Boiler'>Boiler</option>
									<option value='Elektryczny_ogrzewacz_wody'>Elektryczny ogrzewacz wody</option>
									<option value='Gazowy_ogrzewacz_wody'>Gazowy ogrzewacz wody</option>
									<option value='Elektryczne_przepływowe_ogrzewacze_wody'>
										Elektryczne przepływowe ogrzewacze wody
									</option>
									<option value='Wymiennikowe ogrzewacze ciepłej wody'>Wymiennikowe ogrzewacze ciepłej wody</option>
								</Form.Select>
							</Form.Group>
							{['Boiler', 'Elektryczny_ogrzewacz_wody', 'Gazowy_ogrzewacz_wody', 'Elektryczne_przepływowe_ogrzewacze_wody'].includes(selectedOption.type_of_heating_water) ? (
								<Form.Group as={Col} md='12' controlId='validationCustom03'>
									<Form.Label className='form_data'>Rodzaj zainstalowanych rur</Form.Label>
									<Form.Select
										aria-label='Default select example'
										name='types_of_pipes'
										id='types_of_pipes'
										value={selectedOption.types_of_pipes}
										onChange={handleSelectChange}
										required
									>
										<option value=''>Wybierz rodzaj</option>
										<option value='Stalowe'>Stalowe</option>
										<option value='Miedziane'>Miedziane</option>
										<option value='PCV'>PCV</option>
									</Form.Select>
								</Form.Group>
							) : null}
						</Row>

						<Row className='mb-3'>
							<Form.Group as={Col} md='6' controlId='validationCustom01'>
								<Form.Label className='form_data'>Rodzaj zainstalowanych grzejników:</Form.Label>
								<Form.Select
									aria-label='Default select example'
									name='type_of_heaters'
									id='type_of_heaters'
									value={selectedOption.type_of_heaters}
									onChange={handleSelectChange}
									required
								>
									<option value=''>Wybierz rodzaj</option>
									<option value='Grzejnik elektryczny'>Grzejnik elektryczny</option>
									<option value='Grzejnik dekoracyjny'>Grzejnik dekoracyjny</option>
									<option value='Grzejnik klasyczny'>Grzejnik klasyczny</option>
								</Form.Select>
							</Form.Group>

							<Form.Group as={Col} md='6' controlId='validationCustom02'>
								<Form.Label className='form_data'>Rodzaj wentylacji:</Form.Label>
								<Form.Select
									aria-label='Default select example'
									name='type_of_ventilation'
									id='type_of_ventilation'
									value={selectedOption.type_of_ventilation}
									onChange={handleSelectChange}
									required
								>
									<option value=''>Wybierz jedną z rodzai wentylacji</option>
									<option value='Grawitacyjna'>Grawitacyjna</option>
									<option value='Mechaniczna'>Mechaniczna</option>
									<option value='Rekuperacja'>Rekuperacja</option>
								</Form.Select>
							</Form.Group>
						</Row>

						<Row className='mb-3'>
							<Form.Group as={Col} md='6' controlId='validationCustom01'>
								<Form.Label className='form_data'>Materiał miary okien:</Form.Label>
								<Form.Select
									aria-label='Default select example'
									name='window_measurement_material'
									id='window_measurement_material'
									value={selectedOption.window_measurement_material}
									onChange={handleSelectChange}
									required
								>
									<option value=''>Wybierz rodzaj</option>
									<option value='Drewniane'>Drewniane</option>
									<option value='Aluminiowe'>Aluminiowe</option>
									<option value='PCV'>PCV</option>
								</Form.Select>
							</Form.Group>
							<Form.Group as={Col} md='6' name='number_of_panes_in_window' controlId='validationCustom02'>
								<Form.Label className='form_data'>Liczba szyb w oknach:</Form.Label>
								<Form.Select
									aria-label='Default select example'
									name='number_of_panes_in_window'
									id='number_of_panes_in_window'
									value={selectedOption.number_of_panes_in_window}
									onChange={handleSelectChange}
									required
								>
									<option value=''>Wybierz rodzaj</option>
									<option value='Jednokomorowa szyba zespolona'>Jednokomorowa szyba zespolona</option>
									<option value='Warstwowa jednokomorowa szyba zespolona'>
										Warstwowa jednokomorowa szyba zespolona
									</option>
									<option value='Dwukomorowa szyba zespolona'>Dwukomorowa szyba zespolona</option>
									<option value='Warstwowa dwukomorowa szyba zespolona'>Warstwowa dwukomorowa szyba zespolona</option>
									<option value='Trójkomorowa szyba zespolona'>Trójkomorowa szyba zespolona</option>
									<option value='Szyby termoizolacyjne'>Szyby termoizolacyjne</option>
								</Form.Select>
							</Form.Group>
							<Form.Group as={Col} md='12' controlId='validationCustom01'>
								<Form.Label className='form_data'>Drzwi zewnętrzne lokalu:</Form.Label>
								<InputGroup>
									<Form.Control
										as='textarea'
										aria-label='With textarea'
										name='OutDor'
										value={data.OutDor}
										onChange={handeInputData}
										required
										aria-describedby='OutDorHelpBlock'
									/>
								</InputGroup>
								<Form.Text id='OutDorHelpBlock' muted>
									<p>
										Należy podać:
										<ul>
											<li>Czy jest przeszklenie</li>
											<li>Wymiary drzwi</li>
											<li>Z czego zostały wykonane drzwi</li>
										</ul>
									</p>
								</Form.Text>
							</Form.Group>
						</Row>

						<Row className='mb-3'>
							<Form.Group as={Col} md='12' controlId='validationCustom01'>
								<Form.Label className='form_data'>Dodatkowe informacje o nieruchomości (Opcjonalne):</Form.Label>
								<InputGroup>
									<Form.Control
										as='textarea'
										aria-label='With textarea'
										name='otherInformation'
										value={data.otherInformation}
										onChange={handeInputData}
									/>
								</InputGroup>
							</Form.Group>
							<Form.Group as={Col} md='12' controlId='validationCustom01'>
								<Form.Label className='form_data'>Dach jeżeli graniczy z ocenianym lokalem (Jeśli wiadomo):</Form.Label>

								<InputGroup>
									<Form.Control
										as='textarea'
										aria-label='With textarea'
										name='roofInformation'
										value={data.roofInformation}
										onChange={handeInputData}
										aria-describedby='roofInformationHelpBlock'
									/>
								</InputGroup>
								<Form.Text id='roofInformationHelpBlock' muted>
									<p>
										Należy podać:
										<ul>
											<li>Typ</li>
											<li>Kąt nachylenia</li>
											<li>Budowa</li>
											<li>Grubość warstw</li>
										</ul>
									</p>
								</Form.Text>
							</Form.Group>
							<Form.Group as={Col} md='12' controlId='validationCustom01'>
								<Form.Label className='form_data'>
									Strop nad ostatnia kondygnacją jeżeli dotyczy obiektu (Jeśli wiadomo):
								</Form.Label>
								<InputGroup>
									<Form.Control
										as='textarea'
										aria-label='With textarea'
										name='CeilingInformation'
										value={data.CeilingInformation}
										onChange={handeInputData}
										aria-describedby='CeilingInformationHelpBlock'
									/>
								</InputGroup>
								<Form.Text id='CeilingInformationHelpBlock' muted>
									<p>
										Należy podać:
										<ul>
											<li>Typ</li>
											<li>Budowa</li>
											<li>Grubość</li>
										</ul>
									</p>
								</Form.Text>
							</Form.Group>
							<Form.Group as={Col} md='12' controlId='validationCustom01'>
								<Form.Label className='form_data'>Podłoga na gruncie jeżeli dotyczy obiektu(Jeśli wiadomo):</Form.Label>
								<InputGroup>
									<Form.Control
										as='textarea'
										aria-label='With textarea'
										name='floorInformation'
										value={data.floorInformation}
										onChange={handeInputData}
										aria-describedby='floorInformationHelpBlock'
									/>
								</InputGroup>
								<Form.Text id='floorInformationHelpBlock' muted>
									<p>
										Należy podać:
										<ul>
											<li>Typ</li>
											<li>Budowa</li>
											<li>Grubość</li>
											<li>Temperatura po obu stronach</li>
										</ul>
									</p>
								</Form.Text>
							</Form.Group>

							<Form.Group as={Col} md='12' controlId='validationCustom01'>
								<Form.Label className='form_data'>Strop nad piwnicą jeżeli dotyczy obiektu (Jeśli wiadomo):</Form.Label>
								<InputGroup>
									<Form.Control
										as='textarea'
										aria-label='With textarea'
										name='CelingUnder'
										value={data.CelingUnder}
										onChange={handeInputData}
										aria-describedby='CelingUnderHelpBlock'
									/>
								</InputGroup>
								<Form.Text id='CelingUnderHelpBlock' muted>
									<p>
										Należy podać:
										<ul>
											<li>Typ</li>
											<li>Budowa</li>
											<li>Grubość</li>
											<li>Temperatura po obu stronach</li>
										</ul>
									</p>
								</Form.Text>
							</Form.Group>
						</Row>
						<p className='form_data_title'>Wymagane oświadczenia:</p>
						{['checkbox'].map(type => (
							<div key={`default-${type}`} className='mb-3'>
								<Form.Check
									required
									type={type}
									id='checkboxStatement'
									checked={checkboxes.checkboxStatement}
									onChange={handleCheckboxChange}
									name='checkboxStatement'
									label={`Oświadczam, że podane dane są zgodne ze stanem faktycznym wszystkich parametrów budynku. Oświadczam, że posiadam prawo do dysponowania nieruchomością na cele sporządzenia Świadectwa `}
								/>

								<Form.Check
									required
									type={type}
									label={`Akceptuję regulamin usługi VALUATION HUB oraz wyrażam zgodę na przetwarzanie moich danych osobowych. Zezwalam także na kontakt w sprawie sporządzenia świadectwa energetycznego.`}
									checked={checkboxes.checkboxregulations}
									onChange={handleCheckboxChange}
									name='checkboxregulations'
									id='checkboxregulations'
								/>
							</div>
						))}
						<Button type='submit' value='send'>
							Prześlij formularz
						</Button>
					</Form>
				</div>
			</Container>
		</section>
	)
}

export default Form_Validate
