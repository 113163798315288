import React, { useState, useRef } from 'react'
import { Link, animateScroll as scroll } from 'react-scroll'
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import CardGroup from 'react-bootstrap/CardGroup'
import blok_m from '../../Img/blok_mieszkalny.jpg'
import lokal_u from '../../Img/lokal_użytkowy.jpg'
import dom_r from '../../Img/dom_rodzinny.jpg'

import Form_Validate from '../Form_Validate/Form_Validate'

function Form() {
	const [isDisplayed, setIsDisplayed] = useState(false)

	const checkbox1Ref = useRef(null)
	const checkbox2Ref = useRef(null)
	const checkbox3Ref = useRef(null)

	const handleButton1Click = () => {
		setIsDisplayed(true)
		checkbox1Ref.current.click()
		scroll.scrollToBottom()
	}

	const handleButton2Click = () => {
		setIsDisplayed(true)
		checkbox2Ref.current.click()
		scroll.scrollToBottom()
	}

	const handleButton3Click = () => {
		setIsDisplayed(true)
		checkbox3Ref.current.click()
		scroll.scrollToBottom()
	}

	return (
		<>
			<section className='Form' id='price'>
				<Container>
					<h1 className='text-center'>Formularz Świadectwa charakterystyki energetycznej </h1>
					<h3 className='text-center mt-5 mb-5'>Aby przejść do formularza wybierz jedną z opcji nieruchomości.</h3>
					<Container>
						<CardGroup>
							<Card className='form_card'>
								<Card.Img variant='top' src={blok_m} />
								<Card.Body>
									<Card.Title className='Form_img_title'>Budynek wielomieszkaniowy</Card.Title>
									<Card.Text>
										<p className='mx-3'>
											Cena:300zł <br /> brutto
										</p>
									</Card.Text>
									<Card.Text></Card.Text>
								</Card.Body>
								<Card.Footer>
									<Link to='form_validate_id' spy={true} smooth={true} offset={-90} duration={500}>
										<button onClick={handleButton1Click} className='form_img_button'>
											Wybierz
										</button>
									</Link>
								</Card.Footer>
							</Card>
							<Card className='form_card'>
								<Card.Img variant='top' src={lokal_u} />
								<Card.Body>
									<Card.Title className='Form_img_title'>
										Lokal <br /> użytkowy
									</Card.Title>
									<Card.Text>
										<p className='mx-3'>
											Cena:450zł <br /> brutto
										</p>
									</Card.Text>
									<Card.Text></Card.Text>
								</Card.Body>
								<Card.Footer>
									<Link to='form_validate_id' spy={true} smooth={true} offset={-90} duration={500}>
										<button onClick={handleButton2Click} className='form_img_button'>
											Wybierz
										</button>
									</Link>
								</Card.Footer>
							</Card>
							<Card className='form_card'>
								<Card.Img variant='top' src={dom_r} />
								<Card.Body>
									<Card.Title className='Form_img_title'>
										Dom <br /> jedno i wielorodzinny
									</Card.Title>
									<Card.Text>
										<p className='mx-3'>
											Cena:650zł <br /> brutto
										</p>
									</Card.Text>
									<Card.Text></Card.Text>
								</Card.Body>
								<Card.Footer>
									<Link to='form_validate_id' spy={true} smooth={true} offset={-90} duration={500}>
										<button onClick={handleButton3Click} className='form_img_button'>
											Wybierz
										</button>
									</Link>
								</Card.Footer>
							</Card>
						</CardGroup>
					</Container>
				</Container>
			</section>
			<Form_Validate isDisplay={isDisplayed} ref1={checkbox1Ref} ref2={checkbox2Ref} ref3={checkbox3Ref} />
		</>
	)
}

export default Form
