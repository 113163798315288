import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesDown } from '@fortawesome/free-solid-svg-icons'
import { fa1 } from '@fortawesome/free-solid-svg-icons'
import { fa2 } from '@fortawesome/free-solid-svg-icons'
import { fa3 } from '@fortawesome/free-solid-svg-icons'

function HowItWorks() {
	return (
		<section className='howItworks' id='how_works'>
			<h1 className='text-center'>W jaki sposób to działa ?</h1>
			<div className='underline'></div>
			<FontAwesomeIcon className='howItworkFaIcon' icon={faAnglesDown} />
			<Container>
				<div className='howItworks_list'>
					<Row>
						<Col md={1}>
							<FontAwesomeIcon className='howItWorks_fa_icon' icon={fa1} />
						</Col>
						<Col className='howItWorks_descripe_text' md={10}>
							Wypełniasz formularz, w którym podajesz rodzaj Twojej nieruchomości, a także informacje zgodne z jego
							specyfikacją, na podstawie których możemy uzupełnić Twoje Świadectwo charakterystyki energetycznej.
						</Col>
					</Row>
					<Row>
						<Col md={1}>
							<FontAwesomeIcon className='howItWorks_fa_icon' icon={fa2} />
						</Col>
						<Col className='howItWorks_descripe_text' md={10}>
							Po właściwym wypełnieniu formularza otrzymujesz informacje o dokonaniu płatności. Po uiszczeniu opłaty
							rozpoczynamy pracę nad tworzeniem Świadectwa.
						</Col>
					</Row>
					<Row>
						<Col md={1}>
							<FontAwesomeIcon className='howItWorks_fa_icon' icon={fa3} />
						</Col>
						<Col className='howItWorks_descripe_text' md={10}>
							Jeśli pierwsze dwa kroki zostały wykonane poprawnie, w ciągu kilku dni przesyłamy na podany przez Ciebie
							adres e-mail Świadectwo charakterystyki energetycznej.
						</Col>
					</Row>
				</div>
				<div className='d-flex justify-content-center align-items-center'>
					<button className='describe_button_buy'>
						<a className='describe_btn' href='/Cena'>
							Zamów teraz
						</a>
					</button>
				</div>
			</Container>
		</section>
	)
}

export default HowItWorks
