function Footer_Section() {
	const data = new Date()
	return (
		<footer>
			<p className='footer_p'>Copyright {data.getFullYear()} Świadectwa Energetyczne | All Rights Reserved </p>
		</footer>
	)
}

export default Footer_Section
