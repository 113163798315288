import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { useState } from 'react'

import LightBulb from '../Img/light_bulb.svg'

function MainNav() {
	const [activeOption, setActiveOption] = useState(null)

	const handleClick = option => {
		setActiveOption(option)
	}
	return (
		<>
			<Navbar bg='light' expand='xl' id='navigation' className='position-sticky top-0 navbar'>
				<Container>
					<Navbar.Brand href='/'>
						<img className='navbarBrand' src={LightBulb} alt='logo' /> <span>Świadectwa Energetyczne</span>
					</Navbar.Brand>

					<Navbar.Toggle aria-controls='basic-navbar-nav' />
					<Navbar.Collapse id='basic-navbar-nav'>
						<Nav className='m-auto'>
							<Nav.Link className='main_nav_link' href='/Dlaczego_wazne'>
								Dlaczego ważne
							</Nav.Link>
							<Nav.Link className='main_nav_link' href='/Jak_zamowic'>
								Jak Zamówić
							</Nav.Link>
							<Nav.Link className='main_nav_link' href='/Cena'>
								Ceny
							</Nav.Link>
							{/* <Nav.Link className='main_nav_link' href='/aboutus'>
								O nas
							</Nav.Link> */}
							<Nav.Link className='main_nav_link' href='/faq'>
								FaQ
							</Nav.Link>
							<Nav.Link className='main_nav_link' href='/Regulamin'>
								Regulamin
							</Nav.Link>
							<Nav.Link className='main_nav_link' href='/Polityka'>
								Polityka
							</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</>
	)
}

export default MainNav
