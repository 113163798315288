function Regulations() {
	return (
		<>
			<section>
				<div className='header-bg'>
					<div className='container'>
						<h4 className='text-center py-5'>Regulamin Korzystania z Serwisu VALUATION HUB</h4>
						<div className='d-flex justify-content-center  text-start flex-column px-5 fonts'>
							<p className=' py-5 px-4'>
								Niniejszy Regulamin określa ogólne warunki, zasady oraz sposób świadczenia usług drogą elektroniczną
								przez Valuation Sp. z o. o. z siedzibą w Jasionce, Jasionka 954E, 36-002 Jasionka, wpisaną do Rejestru
								Przedsiębiorców w Sądzie Rejonowym w Rzeszowie XII Wydział Krajowego Rejestru Sądowego pod numerem
								0000830618, posługującą się nadanym jej numer identyfikacji podatkowej (NIP): 5170405371 oraz numerem
								REGON: 385615832 za pośrednictwem serwisu internetowego https://swiadectwaenergetyczne.valuationhub.pl/
							</p>
							<p className='text-center'>
								§1 <br />
								<p>DEFINICJE</p>
							</p>
							<p>
								<ol>
									<li>Ilekroć w niniejszym Regulaminie jest mowa o:</li>
								</ol>
								<ol>
									<li> Administratorze – rozumie się przez to Valuation Sp. z o. o. z siedzibą w Jasionce;</li>
									<li>
										Kliencie/Użytkowniku – rozumie się przez to osobę fizyczną posiadającą zdolność do czynności
										prawnych, osobę prawną, a także osobę niebędącą osobą prawną, której ustawa przyznaje zdolność
										prawną na rzecz których Sprzedawca świadczyć będzie usługi na podstawie umowy o świadczenie usług
										drogą elektroniczną;
									</li>
									<li>
										Sprzedającym – rozumie się przez to podmiot posiadający stosowne uprawnienia, wystawiający odpłatnie
										świadectwa charakterystyki energetycznej, na podstawie danych wysyłanych w formularzu zamówienia;
									</li>
									<li>Regulaminie – rozumie się przez to niniejszy regulamin;</li>
									<li>
										Usługach – rozumie się przez to usługi, na które zamówienia można składać w serwisie, tj. świadectwa
										charakterystyki energetycznej budynków;
									</li>
									<li>
										Serwisie – rozumie się przez to stronę internetową, umożliwiająca składanie zamówień na usługi
										wykonania świadectw charakterystyki energetycznej budynków
										https://swiadectwaenergetyczne.valuationhub.pl/ wraz z jej Bazami Danych;
									</li>

									<ol>
										<li>
											Formularzu zamówienia – rozumie się przez to formularz dostępny pod adresem
											https://swiadectwaenergetyczne.valuationhub.pl/ umożliwiający złożenie zamówienia;
										</li>
									</ol>
									<li>
										Zamówieniu - rozumie się przez to oświadczenie woli Klienta stanowiące ofertę zawarcia umowy
										sprzedaży usługi ze sprzedawcą;
									</li>
									<li>
										Umowie sprzedaży – rozumie się przez to umowę sprzedaży usługi zawarta między klientem, a sprzedawcą
										za pośrednictwem Serwisu.
									</li>
									<li>
										RODO – rozumie się przez to rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27
										kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w
										sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (tzw. ogólne
										rozporządzenie o ochronie danych).
									</li>
								</ol>
							</p>
							<p className='text-center'>
								§2 <br />
								<p className='text-uppercase'>Postanowienia ogólne</p>
							</p>
							<ol>
								<li>
									Wszelkie prawa do Serwisu Internetowego, w tym majątkowe prawa autorskie, prawa własności
									intelektualnej do jego nazwy, domeny internetowej, strony internetowej Serwisu, a także do wzorców,
									formularzy oraz logotypów należą do Administratora, a korzystanie z nich może następować wyłącznie w
									sposób określony i zgodny z Regulaminem.
								</li>
								<li>
									Użytkownicy są zobowiązani do przestrzegania Regulaminu. Akceptując niniejszy Regulamin Użytkownik
									wyraża zgodę na jego warunki i zobowiązuje się do ich przestrzegania. Korzystanie z Serwisu jest
									jednoznaczne z akceptacją Regulaminu.
								</li>
								<li>
									Administrator Serwisu dokłada wszelkich starań, aby dane były aktualne i na bieżąco aktualizowane.
								</li>
								<li>
									Administrator stosuje mechanizm plików "cookies", które podczas korzystania przez Użytkowników ze
									strony internetowej Serwisu, zapisywane są przez serwer Administratora na dysku twardym urządzenia
									końcowego Użytkownika. Stosowanie plików "cookies" ma na celu poprawne działanie strony internetowej
									Serwisu na urządzeniach Użytkowników. Mechanizm ten nie wpływa negatywnie na działanie urządzenia
									Użytkownika oraz nie powoduje zmian konfiguracyjnych w urządzeniach Użytkowników ani w oprogramowaniu
									zainstalowanym na tych urządzeniach. Każdy Użytkownik może wyłączyć mechanizm „cookies” w przeglądarce
									internetowej swojego urządzenia . Administrator wskazuje, że wyłączenie „cookies” może jednak
									spowodować utrudnienia lub uniemożliwić korzystanie ze strony internetowej Serwisu.
								</li>
								<li>
									Administrator oświadcza, iż publiczny charakter sieci Internet i korzystanie z usług świadczonych
									drogą elektroniczną wiązać może się z zagrożeniem pozyskania i modyfikowania danych Użytkowników przez
									osoby nieuprawnione, dlatego Użytkownicy powinni stosować właściwe środki techniczne, które
									zminimalizują wskazane wyżej zagrożenia. W szczególności stosować oprogramowanie antywirusowe i
									chroniące tożsamość korzystających z sieci Internet.
								</li>
								<li>
									Użytkownik jest uprawniony do korzystania z zasobów Serwisu wyłącznie na własny użytek. Nie jest
									dopuszczalne wykorzystywanie zasobów i funkcji Serwisu w celu prowadzenia przez Użytkownika
									działalności, która naruszałaby interes Administratora, w tym zwłaszcza w celu prowadzenia
									działalności konkurencyjnej w stosunku do Administratora.
								</li>
								<li>
									Administrator Serwisu nie ponosi odpowiedzialności za pojawienie się w Serwisie błędnej lub
									nieprawdziwej informacji oraz za sposób jej użycia. Serwis nie może być traktowany jako oficjalne
									źródło informacji, które może wywierać wpływ na jakąkolwiek sytuację prawną lub faktyczną.
								</li>
								<li>
									Serwis nie ponosi odpowiedzialności za awarie, przerwy spowodowane naprawami czy też przerwami
									technicznym, w tym zwłaszcza za okoliczności leżące po stronie dostawców sieci Internet lub inne
									podobne .
								</li>
							</ol>
							<p className='text-center'>
								§3 <br />
								<p className='text-uppercase'>INFORMACJE DOTYCZĄCE USŁUG I ICH ZAMAWIANIA</p>
							</p>
							<ol>
								<li>
									Administrator poprzez serwis https://swiadectwaenergetyczne.valuationhub.pl/ zbiera zamówienia na
									usługi za pośrednictwem sieci Internet.
								</li>
								<li>
									Administrator przekazuje dane zamówienia sprzedawcy, podmiotowi posiadającemu uprawnienia do
									wystawienia świadectwa charakterystyki energetycznej,
								</li>
								<li>
									Informacje znajdujące się na stronach internetowych serwisu nie stanowią oferty w rozumieniu przepisów
									prawa. Klient, składa ofertę kupna określonej Usługi (Zamówienie) na warunkach podanych w jej opisie.
								</li>
								<li>Cena usługi podana jest w złotych polskich (PLN) i nie zawiera kosztów dostawy.</li>
								<li>Zamówienia można składać poprzez serwis za pomocą formularza zamówienia.</li>
								<li>
									Klient zobowiązuje się do podawania w Formularzu Zamówienia prawdziwych, aktualnych i wszystkich
									niezbędnych danych.
								</li>
							</ol>
							<p className='text-center'>
								§4 <br />
								<p className='text-uppercase'>ZAWARCIE UMOWY SPRZEDAŻY</p>
							</p>
							<ol>
								<li>W celu zawarcia Umowy Sprzedaży Klient składa Zamówienie.</li>
								<li>
									Po złożeniu Zamówienia Administrator przekazuje dane Sprzedawcy, który potwierdza Klientowi otrzymanie
									Zamówienia.
								</li>
								<li>Potwierdzenie otrzymania Zamówienia następuje poprzez przesłanie wiadomości mailowej.</li>
								<li>
									Z chwilą otrzymania przez Klienta wiadomości mailowej, o której mowa w pkt 3 niniejszego paragrafu,
									zostaje zawarta Umowa Sprzedaży między Klientem, a Sprzedawcą.
								</li>
								<li>Umowa Sprzedaży jest potwierdzana dowodem zakupu, który będzie dołączany do Usługi.</li>
							</ol>
							<p className='text-center'>
								§5 <br />
								<p className='text-uppercase'>PŁATNOŚĆ</p>
							</p>
							<ol>
								<li>
									Klient przesyła płatność za pośrednictwem przelewu internetowego na konto Sprzedawcy wskazane w
									wiadomości mailowej. Klient dokonuje zapłaty przed rozpoczęciem realizacji Zamówienia.
								</li>
								<li>Usługa jest wykonywana dopiero po jej opłaceniu.</li>
							</ol>
							<p className='text-center'>
								§6 <br />
								<p className='text-uppercase'>KOSZT i SPOSÓB DOSTAWY</p>
							</p>
							<ol>
								<li>
									Dostawa jest realizowana drogą elektroniczną na adres mailowy wskazany przez Klienta w formularzu
									zamówienia.
								</li>
								<li>
									Klient dodatkowo może wykupić usługę fizycznej dostawy na dowolnie wskazany adres. Koszty dostawy są
									ustalane w wiadomości mailowej oraz są pokrywane w całości przez Klienta.
								</li>
								<li>
									Fizyczna dostawa realizowana jest wyłącznie na terenie Polski za pośrednictwem Poczty Polskiej lub
									firmy kurierskiej.
								</li>
							</ol>
							<p className='text-center'>
								§7 <br />
								<p className='text-uppercase'>PRAWO ODSTĄPIENIA OD UMOWY</p>
							</p>
							<ol>
								<li>
									Klient ma możliwość odstąpienia od umowy przed wykonaniem Usługi poprzez wysłanie zgłoszenia na adres
									e-mail office@valuationhub.pl. Jeśli Sprzedawca wystawił już świadectwo charakterystyki energetycznej,
									a Klient chce dokonać zwrotu (przed wysłaniem świadectwa charakterystyki energetycznej z podpisem
									uprawnionego audytora) – zwrot zostanie pomniejszony o koszt pracy audytora.
								</li>
							</ol>
							<p className='text-center'>
								§8 <br />
								<p className='text-uppercase'>WARUNKI TECHNICZNE POZWALAJĄCE KORZYSTAĆ Z USŁUGI</p>
							</p>
							<ol>
								<li>
									Dla prawidłowego funkcjonowania usługi u Użytkownika niezbędne jest spełnienie następujących wymogów
									technicznych:
								</li>
								<li>posiadanie urządzenia pozwalającego na wyświetlanie stron internetowych,</li>
								<li>połączenie z siecią Internet,</li>
								<li>
									korzystanie z przeglądarki internetowej, która umożliwia wyświetlanie na ekranie urządzenia dokumentów
									hipertekstowych (HTML), powiązanych w sieci Internet przez usługę WWW, która obsługuje język skryptowy
									JavaScript, a ponadto akceptuje pliki cookies
								</li>
								<li>Posiadanie aktywnego konta poczty elektronicznej (e-mail),</li>
							</ol>
							<p className='text-center'>
								§9 <br />
								<p className='text-uppercase'>PRAWA I OBOWIĄZKI UŻYTKOWNIKÓW/ KLIENTÓW</p>
							</p>
							<ol>
								<li>Użytkownikom przysługuje prawo dostępu do danych przesłanych w formularzu zamówienia.</li>
								<li>
									Użytkownikom przysługuje prawo zgłaszania treści niepożądanych i niedozwolonych umieszczonych w
									serwisie Administratorowi.
								</li>
								<li>
									Użytkownik zobowiązany jest do powstrzymania od wszelkich praktyk, które mogłyby narazić
									Administratora na szkodę.
								</li>
								<li>
									Użytkownik zobowiązany jest do nieingerowania technicznego i merytorycznego w treść serwisu, jak
									również powstrzymania się od działań wpływających na funkcjonowanie techniczne serwisu i/lub odbiór
									serwisu przez innych użytkowników, w szczególności od spamowania, oraz nieuzgodnionych działań
									reklamowych.
								</li>
								<li>
									Użytkownik zobowiązany jest do przestrzegania przepisów prawa oraz dobrych obyczajów, m.in. poprzez
									powstrzymywanie się od publikacji treści naruszających dobra osobiste lub uczucia innych Użytkowników,
									treści powszechnie uznawanych za wulgarne, dyskryminujące, obsceniczne, poufne, naruszające granice
									dobrego smaku i inne treści, które w ocenie Administratora zostaną uznane za niewłaściwe.
								</li>
								<li>Użytkownik zobowiązany jest do przestrzegania postanowień niniejszego Regulaminu.</li>
							</ol>
							<p className='text-center'>
								§10 <br />
								<p className='text-uppercase'>REKLAMACJE</p>
							</p>
							<ol>
								<li>
									Wszelkie reklamacje należy składać do Administratora za pośrednictwem formularza kontaktowego
									umieszczonego na stronie https://swiadectwaenergetyczne.valuationhub.pl/ lub pocztą elektroniczną na
									adres e-mail office@valuationhub.pl, pocztą bezpośrednio na adres Administrator.
								</li>
								<li>
									W reklamacji należy podać dane Użytkownika/Klienta niezbędne do przesłania informacji o wyniku
									rozpatrzenia reklamacji oraz opis, na czym polegały nieprawidłowości w funkcjonowaniu serwisu lub
									usłudze świadczonej przez Sprzedawcę, jak również określić żądanie Użytkownika/Klienta.
								</li>
								<li>
									Administrator rozpatrzy reklamację i poinformuje Użytkownika/Klienta o wyniku jej rozpatrzenia
									terminie 30 dni od dnia otrzymania reklamacji.
								</li>
							</ol>
							<p className='text-center'>
								§11 <br />
								<p className='text-uppercase'>REGULACJE PRAWNE I OCHRONA DANYCH OSOBOWYCH</p>
							</p>
							<ol>
								<li>
									Informacje zawarte w serwisie przetworzono i zamieszczono z zachowaniem przepisów następujących aktów
									prawnych:
								</li>
								<ol>
									<li>
										ustawa z dnia 30 czerwca 2000 r. - Prawo własności przemysłowej (t.j. Dz. U. z 2021 r. poz. 324.);
									</li>
									<li>
										ustawa z dnia 16 kwietnia 1993 r. o zwalczaniu nieuczciwej konkurencji (t.j. Dz. U. z 2022 r. poz.
										1233);
									</li>
									<li> ustawa z dnia 23 kwietnia 1964 r. - Kodeks cywilny (t.j. Dz. U. 2021 r. poz. 1509, 2459.).</li>
								</ol>
								<li>
									Administrator oświadcza, iż wszelkie informacje zostały umieszczone w Serwisie za przewidzianą
									przepisami prawa zgodą wyrażoną przez Sprzedawców oraz inne profesjonalne podmioty.
								</li>
								<li>
									Serwis funkcjonuje zgodnie z rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27
									kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w
									sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (tzw. ogólne
									rozporządzenie o ochronie danych).
								</li>
								<li>
									Serwis i jego elementy, w szczególności oprogramowanie i bazy danych, są chronione przepisami prawa
									polskiego (Ustawa z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych (t.j. Dz. U. z 2022
									r. poz. 655); Ustawa z dnia 27 lipca 2001 r. o ochronie baz danych (t.j. z Dz. U. z 2021 r. poz. 386))
									i stanowią własność Administratora.
								</li>
								<li>
									Administrator posiada wszelkie prawa (włączając prawa autorskie oraz inne prawa z zakresu własności
									intelektualnej) do serwisu (włączając teksty własne Administratora, grafikę i loga). Niedozwolone jest
									kopiowanie, zapisywanie, publikowanie, dystrybuowanie czy reprodukcja informacji zawartych na w/w
									stronie bez uprzedniej, pisemnej zgody, z wyjątkiem zachowania tych treści do użytku prywatnego..
								</li>
								<li>
									Użytkownik korzystając z usług serwisu i podając swoje dane osobowe wyraża zgodę na przetwarzanie jego
									danych osobowych zgodnie z przepisami RODO.
								</li>
								<li>
									Dane osobowe wskazane w ustępie powyższym, jak również dane społecznościowe gromadzone są w celu
									ustalenia preferencji Użytkownika i zaprezentowania najkorzystniejszych ofert Oferujących.
								</li>
								<li>
									Administrator przetwarza dane osobowe Użytkowników w celu świadczenia usług drogą elektroniczną oraz
									innych celów określonych w Regulaminie oraz Polityce prywatności Administratora dostępnej pod adresem:
									https://swiadectwaenergetyczne.valuationhub.pl/ Dane są przetwarzane wyłącznie na podstawie przepisów
									prawa lub zgody wyrażonej przez Użytkownika.
								</li>
								<li>
									Podanie danych jest dobrowolne, przy czym jednocześnie jest niezbędne do realizacji Usług. Dane
									osobowe będą przechowywane przez okres niezbędny do prawidłowej realizacji umowy o świadczenie usług
									drogą elektroniczną lub do czasu odwołania zgody przez Użytkownika.
								</li>
								<li>
									Administrator chroni przekazane mu dane osobowe oraz dokłada wszelkich starań w celu zabezpieczenia
									ich przed nieuprawnionym dostępem lub wykorzystaniem.
								</li>
							</ol>
							<p className='text-center'>
								§12 <br />
								<p className='text-uppercase'>POSTANOWIENIA KOŃCOWE</p>
							</p>
							<ol>
								<li>Regulamin znajduje się na stronie internetowej serwisu.</li>
								<li>
									Treść niniejszego Regulaminu może zostać utrwalona poprzez wydrukowanie, zapisanie na nośniku lub
									pobranie w każdej chwili ze strony internetowej serwisu.
								</li>
								<li>
									Każdy Użytkownik może skorzystać z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia
									roszczeń.
								</li>
								<li>
									W przypadku powstania sporu na gruncie zawartej umowy o świadczenie usług drogą elektroniczną, strony
									będą dążyły do rozwiązania sprawy w sposób polubowny, co nie oznacza zapisu na sąd polubowny. Prawem
									właściwym dla rozstrzygania wszelkich sporów powstałych na gruncie niniejszego Regulaminu jest prawo
									polskie.
								</li>
								<li>
									Administrator zastrzega sobie prawo zmiany niniejszego Regulaminu. Wszystkie umowy oświadczenie usługi
									drogą elektroniczną zawarte przed dniem wejścia w życie nowego Regulaminu są realizowane na podstawie
									Regulaminu, który obowiązywał w dniu zawarcia umowy o świadczenie usługi drogą elektroniczną. Zmiana
									Regulaminu wchodzi w życie w terminie 7 dni od opublikowania w serwisie. Administrator poinformuje
									Użytkownika na 7 dni przed wejściem w życie nowego Regulaminu o zmianie Regulaminu za pomocą
									wiadomości przesłanej drogą elektroniczną zawierającej odnośnik do tekstu zmienionego Regulaminu. W
									razie, gdy Użytkownik nie akceptuje nowej treści Regulaminu obowiązany jest zawiadomić o tym fakcie
									Administratora, co skutkuje rozwiązaniem umowy o świadczenie usług drogą elektroniczną.
								</li>
								<li>Regulamin wchodzi w życie z dniem 05.05.2023.</li>
							</ol>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
export default Regulations
