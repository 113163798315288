import './App.scss'
import MainNav from './Components/Nav/MainNav'
import Describe_Section from './Components/Sections/DescribeSection/Describe'
import Form from './Components/Sections/Chose_form_option/Form'
import HowItWorks from './Components/Sections/HowItWorks/HowItWorks'
import WhyImportant from './Components/Sections/WhyImportant/WhyImportant'
import AboutUs from './Components/Sections/AboutUs/AboutUs'
import Footer_Section from './Components/Sections/FooterSection/Footer_Section'
import { Routes, Route } from 'react-router-dom'
import Regulations from './Components/Sections/Regulations/Regulations'
import Politic from './Components/Sections/Politic/Politic'
import FAQ from './Components/Sections/FAQ/FAQ'

function App() {
	return (
		<>
			<MainNav />
			<Routes>
				<Route path='/' element={<Describe_Section />} />
				<Route path='/Dlaczego_wazne' element={<WhyImportant />} />
				<Route path='/Jak_zamowic' element={<HowItWorks />} />
				<Route path='/Cena' element={<Form />} />
				{/* <Route path='/aboutus' element={<AboutUs />} /> */}
				<Route path='/faq' element={<FAQ />} />
				<Route path='/Regulamin' element={<Regulations />} />
				<Route path='/Polityka' element={<Politic />} />
			</Routes>
			<Footer_Section />
		</>
	)
}

export default App
