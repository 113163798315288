import Container from 'react-bootstrap/Container'
import Accordion from 'react-bootstrap/Accordion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLocationDot, faBook, faHandshake, faMobileScreenButton } from '@fortawesome/free-solid-svg-icons'
import faq_img from '../../Img/faq_img.jpg'

function FAQ() {
	return (
		<section className='FaQ' id='faq'>
			<h1 className='text-center'>Najczęstsze pytania nowych klientów</h1>
			<Container>
				<div className='acording_list_item '>
					<Accordion className=' faq_accordion'>
						<Accordion.Item eventKey='0' className='faq_according_button'>
							<Accordion.Header>Do kiedy należy sporządzić świadectwa charakterystyki energetycznej?</Accordion.Header>
							<Accordion.Body>
								Świadectwo należy przekazać nabywcy lub najemcy gdy budynek lub jego część będzie zbywany lub
								wynajmowany. Dodatkowo obowiązek sporządzenia i przekazania świadectwa dotyczy także zakończenia budowy,
								pozwolenia na użytkowanie. Świadectwo jest również dokumentem wymaganym w większości dotacji i
								dofinansowań.
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey='1'>
							<Accordion.Header className='faq_according_button'>
								Kto jest uprawniony do sporządzania świadectw charakterystyki energetycznej?
							</Accordion.Header>
							<Accordion.Body>
								Osoba, która jest uprawniona do sporządzania świadectwa charakterystyki energetycznej to osoba, która
								wpisana jest do wykazu osób uprawnionych w centralnym rejestrze charakterystyki energetycznej budynków.
								/ Do sporządzenia świadectwa charakterystyki energetycznej uprawniona jest osoba która wpisana jest do
								wykazu osób uprawnionych w centralnym rejestrze charakterystyki energetycznej budynków.
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey='3'>
							<Accordion.Header className='faq_according_button'>
								Czy świadectwo charakterystyki energetycznej ma swoją ważność? / Czy ważność świadectwa charakterystyki
								energetycznej wygasa?
							</Accordion.Header>
							<Accordion.Body>Ważność świadectwa charakterystyki energetycznej wygasa po 10 latach.</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey='4'>
							<Accordion.Header className='faq_according_button'>
								Co oznaczają podstawowe wskaźniki zawarte w świadectwie charakterystyki energetycznej?
							</Accordion.Header>
							<Accordion.Body>
								Podstawowymi wskaźnikami w świadectwie charakterystyki energetycznej są m.in.: zapotrzebowanie na
								energię czy parametry techniczne budynku jak np. adres, data budowy, sposób ogrzewania itp.
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey='5'>
							<Accordion.Header className='faq_according_button'>
								Czy można uzyskać świadectwo charakterystyki energetycznej zdalnie/online?
							</Accordion.Header>
							<Accordion.Body>
								Obecność audytora na miejscu nie jest wymagana. Zgodnie z ustawą z dnia 29.08.2014r. o charakterystyce
								energetycznej budynków od 28 kwietnia 2023 roku świadectwo może być sporządzone w formie elektronicznej
								z podpisem osoby uprawnionej. Jest więc możliwość uzyskania świadectwa online, które możemy sporządzić
								również w formie papierowej i wysłać na wskazany adres.
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey='6'>
							<Accordion.Header className='faq_according_button'>
								Mam problem z wypełnieniem wszystkich parametrów z formularza technicznego. Co zrobić?
							</Accordion.Header>
							<Accordion.Body>
								Najlepiej skontaktować się z deweloperem, poprzednim właścicielem lub zarządcą budynku. Jeśli napiszesz
								do nas, również postaramy się pomóc.
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey='7'>
							<Accordion.Header className='faq_according_button'>Jak odnowić wygasające świadectwo?</Accordion.Header>
							<Accordion.Body>
								Gdy zbliża się data wygaśnięcia świadectwa należy wypełnić adekwatny formularz oraz załączyć wygasające
								świadectwo. Dodatkowo należy opisać czy nieruchomość przeszła istotne zmiany od momentu wystawienia
								poprzedniego świadectwa.
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey='8'>
							<Accordion.Header className='faq_according_button'>
								Jaki jest czas realizacji po opłaceniu zamówienia?
							</Accordion.Header>
							<Accordion.Body>
								Świadectwo zostaje wysłane w ciągu 48h od momentu wysłania maila z potwierdzeniem otrzymania płatności.
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>

					<div>
						<img src={faq_img} className='faq_img' alt='zdjęcie' />
						<p className=' faq_p'>
							Jeśli nie udało ci się odnaleść odpowiedzi na twoje nurtujące pytanie zapraszamy do kontaktu:
						</p>
						<div className='faq_icon fs-5'>
							<span>
								<FontAwesomeIcon className='me-3 faq_color' icon={faEnvelope} /> office@valuationhub.pl
							</span>
							<span>
								<FontAwesomeIcon className='me-3 faq_color' icon={faMobileScreenButton} /> 451 632 356
							</span>
							<span>
								<FontAwesomeIcon className='me-3 faq_color' icon={faLocationDot} /> Valuation Hub Sp. z o.o. Podkarpacki
								Park Naukowo-Technologiczny „Aeropolis” Jasionka 954 E 36-002 Jasionka
							</span>
						</div>
					</div>
				</div>
			</Container>
		</section>
	)
}

export default FAQ
