function Politic() {
	return (
		<>
			<section>
				<div className='header-bg'>
					<div className='container'>
						<h4 className='text-center py-5'>Polityka prywatności</h4>
						<div className='d-flex justify-content-center text-start flex-column px-5 fonts'>
							<p className='text-center'>Rozdział I:</p>
							<p className='text-center'>
								Definicje i zasady ogólne
								<br />
								<p>§1</p>
							</p>
							<p>
								Niniejsza Polityka ochrony danych osobowych (zwana w dalszej części: „Polityką”), określa wymogi i
								zasady ochrony danych osobowych w: Valuation Sp. z o. o. z siedzibą w Jasionce, Jasionka 954E, 36-002
								Jasionka, wpisaną do Rejestru Przedsiębiorców w Sądzie Rejonowym w Rzeszowie XII Wydział Krajowego
								Rejestru Sądowego pod numerem 0000830618, posługującą się nadanym jej numer identyfikacji podatkowej
								(NIP): 5170405371 oraz numerem REGON: 385615832. Niniejsza Polityka jest polityką ochrony danych
								osobowych w rozumieniu rozporządzenia Parlamentu Europejskiego i Rady (UE) z dnia 27 kwietnia 2016 r. w
								sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego
								przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych)(Dz.
								Urz. UE L 119, s. 1), zwanego w dalszej części: „RODO” i stanowi wykonanie obowiązku, o którym mowa w
								art. 24 ust. 2 RODO. Polityka ma zastosowanie do wszystkich danych osobowych przetwarzanych u
								Administratora. Obowiązek ochrony danych osobowych dotyczy każdej osoby, która ma do nich dostęp,
								niezależnie od zajmowanego stanowiska, miejsca wykonywania pracy jak również charakteru stosunku pracy.
								Osoby mające dostęp do danych osobowych są zobowiązane do zapoznania się z Polityką i innymi dokumentami
								uszczegóławiającymi procedury lub instrukcje dotyczące ochrony danych osobowych.
							</p>
							<p className='text-center'>§2</p>
							<p>Występujące w niniejszej Polityce zwroty pisane wielką literą oznaczają:</p>
							<p>
								Polityka – niniejsza polityka ochrony danych osobowych; Administrator – Valuation Sp. z o. o. z siedzibą
								w Jasionce, Jasionka 954E, 36-002 Jasionka, wpisaną do Rejestru Przedsiębiorców w Sądzie Rejonowym w
								Rzeszowie XII Wydział Krajowego Rejestru Sądowego pod numerem 0000830618, posługującą się nadanym jej
								numer identyfikacji podatkowej (NIP): 5170405371 oraz numerem REGON: 385615832. Dane Osobowe – wszelkie
								informacje, dotyczące zidentyfikowanej lub możliwej do zidentyfikowania osoby fizycznej. Możliwa do
								zidentyfikowania osoba fizyczna to osoba, którą można bezpośrednio lub pośrednio zidentyfikować, w
								szczególności na podstawie identyfikatora takiego jak imię i nazwisko, numer identyfikacyjny, dane o
								lokalizacji, identyfikator internetowy lub jeden bądź kilka szczególnych czynników określających
								fizyczną, fizjologiczną, genetyczną, psychiczną, ekonomiczną, kulturową lub społeczną tożsamość osoby
								fizycznej. Dane Szczególnych Kategorii – szczególne kategorie danych określone w art. 9 RODO, w tym:
								dane ujawniające pochodzenie rasowe lub etniczne, poglądy polityczne, przekonania religijne lub
								światopoglądowe, przynależność do związków zawodowych; dane genetyczne, dane biometryczne przetwarzane w
								celu jednoznacznego zidentyfikowania osoby fizycznej; dane dotyczące zdrowia, seksualności lub
								orientacji seksualnej osoby; jak również dane osobowe dotyczące wyroków skazujących oraz naruszeń prawa,
								o których mowa w art. 10 RODO.
							</p>
							<p>
								Naruszenie – naruszenie bezpieczeństwa prowadzące do przypadkowego lub niezgodnego z prawem zniszczenia,
								utracenia, zmodyfikowania, nieuprawnionego ujawnienia lub nieuprawnionego dostępu do danych osobowych
								przesyłanych, przechowywanych lub w inny sposób przetwarzanych.
							</p>
							<p>
								Odbiorca danych – podmiot, któremu udostępniane są dane osobowe. Osoba upoważniona – osoba upoważniona
								do przetwarzania danych osobowych przez Administratora lub osobę przez niego upoważnioną, mająca
								bezpośredni dostęp do danych, przetwarzanych w systemie informatycznym lub w dokumentacji papierowej.
								Podmiot przetwarzający – podmiot, któremu Administrator powierza czynności przetwarzania danych
								osobowych w swoim imieniu.
							</p>
							<p>
								Profilowanie – oznacza dowolną formę zautomatyzowanego przetwarzania danych osobowych, które polega na
								wykorzystaniu danych osobowych do oceny niektórych czynników osobowych osoby fizycznej, w szczególności
								do analizy lub prognozy aspektów dotyczących efektów pracy tej osoby fizycznej, jej sytuacji
								ekonomicznej, zdrowia, osobistych preferencji, zainteresowań, wiarygodności, zachowania, lokalizacji lub
								przemieszczania się. RODO – Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27
								kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w
								sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o
								ochronie danych);
							</p>
							<p>
								UODO – ustawa z dnia 10 maja 2018 r. o ochronie danych osobowych (Dz. U. z 2018, poz. 1000 z późn. zm.);
							</p>
							<p>PUODO – Prezes Urzędu Ochrony Danych Osobowych.</p>
							<p className='text-center'>§3</p>
							<p>Administrator przetwarza Dane Osobowe z poszanowaniem następujących zasad:</p>
							<p>
								Legalność przetwarzania – Administrator zapewnia ochronę prywatności i przetwarza Dane Osobowe zgodnie z
								prawem;
							</p>
							<p>
								Bezpieczeństwo (poufność) – Administrator zapewnia odpowiedni poziom bezpieczeństwa Danych Osobowych,
								podejmując stale działania w tym zakresie;
							</p>
							<p>
								Poszanowanie praw jednostki – Administrator umożliwia każdej osobie, której Dane Osobowe dotyczą
								realizację uprawnień wynikających z powszechnie obowiązujących przepisów prawa;
							</p>
							<p>
								Rozliczalność – Administrator dokumentuje to, w jaki sposób przetwarza Dane Osobowe aby w każdej chwili
								mieć możliwość wykazania, że odbywa się to zgodnie z powszechnie obowiązującymi przepisami prawa;
							</p>
							<p>
								Rzetelność i przejrzystość przetwarzania – Administrator zawsze informuje o przetwarzaniu Danych
								Osobowych w momencie ich zbierania, w tym o celu i podstawie prawnej przetwarzania;
							</p>
							<p>
								Ograniczony cel – Administrator zapewnia, że Dane Osobowe są zbierane w konkretnych i wyraźnych celach,
								które są prawnie uzasadnione oraz Dane Osobowe nie są dalej przetwarzane w sposób niezgodny z tymi
								celami;
							</p>
							<p>
								Minimalizacja – Administrator zapewnia, że Dane Osobowe są przetwarzane wyłącznie w zakresie niezbędnym
								do realizacji celu, dla którego zostały pozyskane;
							</p>
							<p>
								Prawidłowość – Administrator zapewnia, że przetwarzane Dane Osobowe są prawidłowe i w razie potrzeba są
								na bieżąco uaktualniane;
							</p>
							<p>
								Ograniczenie czasowe - Administrator zapewnia, że Dane Osobowe są przetwarzane wyłącznie przez okres, w
								jakim jest to niezbędne dla zrealizowania celów przetwarzania.
							</p>
							<p className='text-center'>Rozdział II:</p>
							<p className='text-center'>
								Bezpieczeństwo Danych Osobowych
								<br />
								<p>§4</p>
							</p>
							<p>
								Administrator jest odpowiedzialny za przetwarzanie i ochronę Danych Osobowych, zgodnie przepisami prawa,
								w tym za zaakceptowanie Polityki.
							</p>
							<p>
								W ramach prowadzonej działalności gospodarczej, Administrator wyznacza Inspektora lub inną osobę
								odpowiedzialną za ochronę Danych Osobowych, który wykonuje zadania w zakresie monitorowania zasad
								przetwarzania Danych Osobowych.
							</p>
							<p>
								Administrator może wyznaczyć zastępców Inspektora oraz inne osoby, które wchodzą w skład Zespołu
								Inspektora i wspomagają wykonywanie zadań monitorowania ochrony Danych Osobowych.
							</p>
							<p className='text-center'>§5</p>
							<p>
								W ramach prowadzonej działalności gospodarczej, Administrator jest odpowiedzialny za zarządzanie
								procesami przetwarzania Danych Osobowych. Realizując ten obowiązek, Administrator:
							</p>
							<ol>
								<li>zarządza czynnościami przetwarzania Danych Osobowych;</li>
								<li>nadaje, zmienia lub cofa uprawnienia do dokonywania operacji przetwarzania Danych Osobowych;</li>
								<li>
									zapoznaje podległych pracowników i współpracowników z zasadami przetwarzania i ochrony Danych
									Osobowych;
								</li>
								<li>zabezpiecza obszar przetwarzanych Danych Osobowych;</li>
								<li>tworzy kopie zapasowych plików z Danymi Osobowymi;</li>
								<li>
									zapewnia legalność procesu udostępniania Danych Osobowych innemu podmiotowi lub osobie, której dane
									dotyczą;
								</li>
								<li>
									zapewnia legalność procesu powierzenia przetwarzania Danych Osobowych innym podmiotom - zgodnie z
									zawartymi umowami powierzenia przetwarzania danych osobowych.
								</li>
							</ol>
							<p className='text-center'>§6</p>
							<p>
								Dobór środków technicznych i organizacyjnych dotyczących przetwarzania i zabezpieczania Danych Osobowych
								realizowany jest w oparciu o szacowanie ryzyka naruszenia praw i wolności osób, których dane dotyczą.
							</p>
							<p>
								Administrator zapewnia stopień bezpieczeństwa odpowiadający ryzyku naruszenia praw i wolności osób
								fizycznych wskutek przetwarzania Danych Osobowych.
							</p>
							<p>
								Przy doborze zabezpieczeń Administrator ocenia ryzyko zarówno w kontekście skutków dla osoby, której
								Dane Osobowe dotyczą, w tym np. dyskryminacja, pozbawienie przysługujących praw, szkody majątkowe i
								niemajątkowe, jak również ryzyko w kontekście skutków, które mogą wyniknąć z niepodjęcia działań
								związanych z zapewnieniem przetwarzania Danych Osobowych zgodnie z RODO.
							</p>
							<p>
								Administrator ustala możliwe do zastosowania organizacyjne i techniczne środki bezpieczeństwa i ocenia
								koszt ich wdrażania. Wyraz środków bezpieczeństwa stanowi Załącznik nr 1 do Polityki.
							</p>
							<p className='text-center'>§7</p>
							<p>
								Planowanie realizacji nowych procesów związanych z przetwarzaniem Danych Osobowych, musi uwzględniać
								zasady ochrony Danych Osobowych w fazie projektowania (Privacy by design) oraz domyślnej ochrony Danych
								Osobowych (Privacy by default).
							</p>
							<p>Administrator zarządza zmianami wpływającymi na prywatność.</p>
							<p>
								W tym celu procedury uruchamiania nowych projektów i inwestycji realizowanych przez Administratora
								uwzględniają konieczność oceny wpływu zmiany na ochronę Danych Osobowych, analizę ryzyka, zapewnienie
								prywatności już na etapie projektowania zmiany, inwestycji, nowego projektu (Privacy by design).
							</p>
							<p>
								Administrator dba o minimalizację zakresu przetwarzanych Danych Osobowych i w tym celu wdraża zasady
								adekwatności, reglamentacji i weryfikacji przydatności Danych Osobowych (Privacy by default).
							</p>
							<p className='text-center'>§8</p>
							<p>
								W przypadku realizacji procesów przetwarzania Danych Osobowych, które ze względu na swój charakter,
								zakres, kontekst i cele z dużym prawdopodobieństwem mogą powodować wysokie ryzyko naruszenia praw lub
								wolności osób fizycznych, przed rozpoczęciem przetwarzania Administrator dokonuje oceny skutków
								planowanych operacji przetwarzania dla ochrony Danych Osobowych, zgodnie z art. 35 RODO.
							</p>
							<p>Za realizację obowiązków, o których mowa w ust. 1 odpowiadają członkowie zarządu Administratora.</p>
							<p>
								Wykonanie oceny skutków dla danego procesu przetwarzania danych jest konsultowane z Inspektorem lub
								osobą odpowiedzialną za ochronę Danych Osobowych, który stwierdza czy w danym przypadku takie działanie
								jest konieczne.
							</p>
							<p>
								Inspektor lub osoba odpowiedzialna za ochronę danych osobowych, w prowadzonym rejestrze czynności
								przetwarzania danych osobowych, wskazuje procesy dla których należy przeprowadzać ocenę skutków oraz
								odnotowuje jej przeprowadzenie.
							</p>
							<p>
								Jeżeli dokonana ocena skutków dla ochrony danych wykaże, że przetwarzanie powodowałoby wysokie ryzyko,
								gdyby nie zostały zastosowane środki w celu zminimalizowania tego ryzyka, to przed rozpoczęciem
								przetwarzania należy skonsultować się z PUODO.
							</p>
							<p>
								W przypadku konieczności przeprowadzenia konsultacji z organem nadzorczym Inspektor lub osoba
								odpowiedzialna za ochronę Danych Osobowych przygotowuje odpowiedni wniosek (zgodnie z art. 36 RODO) i
								kontaktuje się w tej sprawie z PUODO.
							</p>
							<p className='text-center'>Rozdział III:</p>
							<p className='text-center'>
								Upoważnianie osób do przetwarzania Danych Osobowych
								<br />
								<p>§9</p>
							</p>
							<p>Administrator (członkowie zarządu Administratora) jest odpowiedzialny za:</p>
							<ol>
								<li>przygotowanie upoważnienia do przetwarzania Danych Osobowych;</li>
								<li>
									przechowywanie nadanych upoważnień do przetwarzania Danych Osobowych oraz oświadczeń o zachowaniu
									tajemnicy Danych Osobowych i sposobów ich zabezpieczania wraz z aktami osobowymi pracowników lub
									umowami cywilnoprawnymi.
								</li>
								<li>prowadzenie aktualnej ewidencji osób upoważnionych do przetwarzania Danych Osobowych.</li>
							</ol>
							<p>Wzór upoważnienia do przetwarzania Danych Osobowych stanowi Załącznik nr 2 do Polityki.</p>
							<p className='text-center'>§10</p>
							<p>
								Wszystkie osoby, które wykonują czynności związane z przetwarzaniem Danych Osobowych w ramach
								wykonywania zadań służbowych na stanowiskach pracy lub prac zleconych, muszą posiadać pisemne
								upoważnienie do przetwarzania Danych Osobowych oraz podpisać oświadczenie o zachowaniu tajemnicy danych
								oraz sposobów ich zabezpieczenia.
							</p>
							<p>
								Każda osoba upoważniona do przetwarzania danych osobowych przechodzi szkolenie z zasad ochrony danych
								obowiązujących u Administratora.
							</p>
							<p>
								Szkolenia wstępne i okresowe dla osób upoważnionych przeprowadzi Inspektor lub osoba odpowiedzialna za
								ochronę Danych Osobowych wg ustalonego planu.
							</p>
							<p className='text-center'>§11</p>
							<p>Osoba upoważniona do przetwarzania Danych Osobowych jest zobowiązana do:</p>
							<ol>
								<li>
									zapoznania się z obowiązującymi przepisami prawa z zakresu ochrony danych osobowych oraz dokumentacją
									dotyczącą ochrony danych osobowych obowiązujących u Administratora;
								</li>
								<li>przechodzenia okresowych szkoleń z obszaru ochrony Danych Osobowych;</li>
								<li>
									przetwarzania Danych Osobowych wyłącznie w celu i zakresie wynikającym z nałożonych obowiązków
									służbowych;
								</li>
								<li>
									{' '}
									zachowania wyjątkowej staranności przy przetwarzaniu Danych Osobowych, w szczególności danych
									wrażliwych w celu ochrony interesów osób, których Dane Osobowe dotyczą;
								</li>
								<li>
									stosowania obowiązujących u Administratora procedur i środków przetwarzania oraz zabezpieczania Danych
									Osobowych;
								</li>
								<li>zachowania w poufności Danych Osobowych oraz danych objętych tajemnicą przedsiębiorstwa;</li>
								<li>
									zabezpieczenia Danych Osobowych przed: ich utratą, uszkodzeniem lub zniszczeniem, zmianą lub ich
									udostępnieniem osobom nieupoważnionym;
								</li>
								<li>
									dopilnowania, aby przebywanie osób nieupoważnionych w pomieszczeniach, w których przetwarzane są Dane
									Osobowe, miało miejsce wyłącznie w obecności osoby upoważnionej;
								</li>
								<li>
									dopilnowania, aby przeznaczone do usunięcia dokumenty, zawierające Dane Osobowe niszczone były w
									stopniu uniemożliwiającym ich odczytanie - zabronione jest wyrzucanie dokumentów do koszy na śmieci
									bez ich właściwej anonimizacji;
								</li>
								<li>
									przestrzegania procedur właściwego użytkowania systemów informatycznych, w których przetwarza się Dane
									Osobowe, w tym do nieujawniania innym użytkownikom swoich loginów i haseł;
								</li>
								<li>
									zachowania należytej staranności podczas przekazywania Danych Osobowych drogą telefoniczną
									(konieczność właściwej identyfikacji rozmówcy, konieczność ustalenia, czy rozmówca jest uprawniony do
									pozyskania Danych Osobowych, przekazywanie jedynie niezbędnych informacji);
								</li>
								<li>
									przesyłania Danych Osobowych za pomocą sieci Internet jedyne z użyciem metod kryptograficznych
									(szyfrowanie danych, kanały bezpiecznej transmisji);
								</li>
								<li>
									niewysyłania za pomocą wiadomości e-mail Danych Osobowych na prywatne adresy, niekopiowanie danych na
									inne nośniki bez uzasadnionej potrzeby;
								</li>
								<li>
									{' '}
									zachowania należytej ostrożności przy transporcie dokumentów oraz nośników informatycznych,
									zawierających Dane Osobowe;
								</li>
								<li>
									niepozostawiania dokumentów, zawierających Dane Osobowe na urządzeniach wielofunkcyjnych (drukowanie,
									kopiowanie);
								</li>
								<li>
									nieopuszczania stanowiska bez zabezpieczenia dokumentów papierowych, zawierających Dane Osobowe
									(zasada „czystego biurka”) oraz bez zabezpieczania dostępu do danych przetwarzanych w systemie
									informatycznym (zasada „czystego ekranu”);
								</li>
								<li>
									zaprzestania przetwarzania Danych Osobowych po ustaniu stosunku zatrudnienia lub zawartej umowy
									cywilnoprawnej.
								</li>
							</ol>
							<p className='text-center'>Rozdział IV:</p>
							<p className='text-center'>
								Rejestr Czynności Przetwarzania Danych Osobowych
								<br />
								<p>§12</p>
							</p>
							<p>
								Rejestr Czynności Przetwarzania Danych stanowi formę dokumentowania czynności przetwarzania i stanowi
								podstawę systemu ochrony Danych Osobowych, umożliwiając Administratorowi rozliczenie większości
								obowiązków związanych z ochroną Danych Osobowych.
							</p>
							<p>
								Administrator prowadzi Rejestr Czynności Przetwarzania Danych w celu inwentaryzacji Danych Osobowych i
								monitorowania sposobu, w jakim wykorzystuje Dane Osobowe.
							</p>
							<p>
								Inspektor lub osoba odpowiedzialna za ochronę Danych Osobowych inwentaryzuje procesy przetwarzania
								Danych Osobowych w przypisując do nich określone czynności przetwarzania. Inspektor lub osoba
								odpowiedzialna za ochronę Danych Osobowych okresowo dokonuje przeglądów procesów przetwarzania Danych
								Osobowych w celach aktualizacji prowadzonych rejestrów.
							</p>
							<p>Wzór Rejestru Czynności Przetwarzania Danych stanowi Załącznik nr 3 do Polityki.</p>
							<p className='text-center'>Rozdział V:</p>
							<p className='text-center'>
								Podstawy przetwarzania Danych Osobowych
								<br />
								<p>§13</p>
							</p>
							<ol>
								<li>
									Osoby odpowiedzialne za procesy, w których zbierane są Dane Osobowe, mają obowiązek zachowania
									szczególnej staranności przy ich zbieraniu, w tym:
								</li>
								<ol>
									<li>
										sprawdzać czy są spełnione podstawy prawne na pozyskiwanie Danych Osobowych, zgodnie z art. 6 RODO
										oraz art. 9 – 10 RODO;
									</li>
									<li>
										zbierać Dane Osobowe dla określonych, zgodnych z prawem celów realizowanych przez Administratora;
									</li>
									<li>
										zbierać dane w zakresie adekwatnym do celów w jakich dane będą przetwarzane przez Administratora.
									</li>
								</ol>
								<li>
									W przypadku konieczności odbierania zgody na przetwarzanie Danych Osobowych, należy zapewnić
									dobrowolność jej pozyskania oraz powiadamiać o prawie do odwołania takiej zgody.
								</li>
							</ol>
							<p className='text-center'>§14</p>
							<p>
								Dane Osobowe są przetwarzane przez czas określony przez właściwe przepisy prawa lub wewnętrzne przepisy
								Administratora.
							</p>
							<p>
								Za określenie odpowiednich czasów retencji Danych Osobowych w procesach przetwarzania odpowiada
								Administrator (członkowie zarządu Administratora).
							</p>
							<p>
								Dane Osobowe, dla których okres przetwarzania nie wynika z obowiązujących przepisów prawa i dla których
								nie jest możliwe określenie z góry tego okresu w wewnętrznych przepisach, są przetwarzane tak długo, jak
								długo istnieje jednocześnie podstawa prawna oraz cel dla ich przetwarzania.
							</p>
							<p>Ustanie celu przetwarzania Danych Osobowych jest równoznaczne z koniecznością ich usunięcia.</p>
							<p>
								Dane Osobowe przetwarzane wyłącznie w oparciu o przesłankę zgody na przetwarzanie Danych Osobowych są
								usuwane zawsze niezwłocznie po wycofaniu takiej zgody.
							</p>
							<p>
								Administrator, co najmniej jeden raz w każdym roku kalendarzowym, dokonuje weryfikacji zasobów Danych
								Osobowych prowadzonych w formie papierowej jak i elektronicznej, obejmującej:
							</p>
							<ol>
								<li>
									sprawdzenie, czy Dane Osobowe, dla których upłynął okres przechowywania wynikający z przepisów prawa
									lub wewnętrznych przepisów obowiązujących u Administratora zostały usunięte;
								</li>
								<li>
									sprawdzenie, czy w odniesieniu do Danych Osobowych, których czas przechowywania nie został określony
									przez właściwe przepisy prawa lub wewnętrzne przepisy obowiązujące u Administratora, nadal istnieje
									podstawa prawna oraz cel przetwarzania Danych Osobowych.
								</li>
							</ol>
							<p>
								W przypadku ustalenia w trakcie weryfikacji, o której mowa w ust. 6, że okres przetwarzania Danych
								Osobowych upłynął bądź nie istnieje podstawa prawna lub cel do dalszego ich przetwarzania, Dane Osobowe
								powinny zostać trwale usunięte z nośników papierowych, elektronicznych oraz systemów informatycznych.
							</p>
							<p className='text-center'>Rozdział VI:</p>
							<p className='text-center'>
								Realizacja obowiązków informacyjnych i obsługa praw jednostki
								<br />
								<p>§15</p>
							</p>
							<p>
								Osoby, które wykonują zadania związane ze zbieraniem Danych Osobowych są odpowiedzialne za realizację
								obowiązków informacyjnych określonych w art. 13 i 14 RODO. Administrator określa sposób informowania
								osób o przetwarzaniu danych niezidentyfikowanych, tam, gdzie jest to możliwe (np. informacja o objęciu
								obszaru monitoringiem wizyjnym).
							</p>
							<p>Wzór klauzuli informacyjnej stanowi Załącznik nr 4 do Polityki.</p>
							<p>Administrator określa zgodne z prawem i efektywne sposoby wykonywania obowiązków informacyjnych.</p>
							<p className='text-center'>§16</p>
							<p>
								Każdej osobie, której Dane Osobowe przetwarza Administrator przysługują prawa określone w art. 15 – 22
								RODO, w tym:
							</p>
							<ol>
								<li>prawo dostępu do danych jej dotyczących;</li>
								<li>prawo do sprostowania danych;</li>
								<li>prawo do usunięcia danych;</li>
								<li>prawo do ograniczenia przetwarzania;</li>
								<li>prawo do przenoszenia danych;</li>
								<li>prawo do sprzeciwu na przetwarzanie jej danych;</li>
								<li>prawo do niepodlegania decyzji opartej wyłącznie na zautomatyzowanym przetwarzaniu.</li>
							</ol>
							<p>
								Jeżeli osoba zgłosi umotywowany jej szczególną sytuacją sprzeciw względem przetwarzania jej Danych
								Osobowych, które są przetwarzane w oparciu o uzasadniony interes Administratora lub o powierzone
								Administratorowi Dane Osobowe w interesie publicznym, Administrator uwzględni sprzeciw, o ile nie
								zachodzą po stronie Administratora ważne prawnie uzasadnione podstawy do przetwarzania, nadrzędne wobec
								interesów, praw i wolności osoby zgłaszającej sprzeciw, lub podstawy do ustalenia, dochodzenia lub
								obrony roszczeń.
							</p>
							<p>
								W sytuacji powierzania Danych Osobowych podmiotom przetwarzającym lub udostępniania Danych Osobowych
								innym administratorom danych, należy ich powiadamiać o każdym sprostowaniu, usunięciu lub ograniczeniu
								przetwarzania danych, które było wynikiem realizacji wniosku otrzymanego od osoby, której Dane Osobowe
								dotyczą.
							</p>
							<p className='text-center'>Rozdział VII:</p>
							<p className='text-center'>
								Udostępnienie i powierzenie Danych Osobowych
								<br />
								<p>§17</p>
							</p>
							<p>
								Osoby, które udostępniają w imieniu Administratora, Dane Osobowe do podmiotu zewnętrznego (w formie
								papierowej lub elektronicznej), przed ich udostępnieniem mają obowiązek sprawdzić czy istnieją podstawy
								prawne umożliwiające wykonanie tych czynność, w tym:
							</p>
							<p>wymóg prawa dotyczący udostępnienia Danych Osobowych;</p>
							<p>zgoda osoby na udostępnienie Danych Osobowych innemu podmiotowi;</p>
							<p>
								wniosek o udostępnienie Danych Osobowych od podmiotu uprawnionego, ze wskazaniem podstawy prawnej do
								otrzymywania danego rodzaju Danych Osobowych.
							</p>
							<p className='text-center'>§18</p>
							<p>
								W sytuacji powierzania czynności przetwarzania Danych Osobowych zewnętrznemu podmiotowi (podmiotowi
								przetwarzającemu), należy zawrzeć z nim umowę powierzenia przetwarzania danych osobowych obejmującą
								elementy wymienione z art. 28 ust. 3 RODO. W trakcie dokonywania wyboru podmiotu przetwarzającego należy
								zweryfikować czy podmiot ten zapewnia wystarczające gwarancje wdrożenia odpowiednich środków
								technicznych i organizacyjnych, by przetwarzanie spełniało wymogi przepisów RODO i chroniło prawa osób,
								których dane dotyczą.
							</p>
							<p>
								Osoby, które przygotowują umowę z podmiotem zewnętrznym, któremu zlecone zostanie wykonywanie czynności
								związanych z przetwarzaniem Danych Osobowych, zobowiązane są skonsultować odpowiednie zapisy dotyczące
								powierzenia przetwarzania danych z Administratorem lub inną wskazaną przez niego osobą .
							</p>
							<p>Wzór umowy powierzenia przetwarzania danych osobowych stanowi Załącznik nr 5 do Polityki.</p>
							<p className='text-center'>§19</p>
							<p>
								W sytuacji przekazywania danych osobowych do podmiotu znajdującego się w państwie trzecim (poza
								Europejskim Obszarem Gospodarczym) należy zarejestrować każdy przypadek eksportu Danych Osobowych w
								Rejestrze Czynności Przetwarzania Danych.
							</p>
							<p className='text-center'>Rozdział VIII:</p>
							<p className='text-center'>
								Postępowanie w sytuacji naruszenia ochrony Danych Osobowych
								<br />
								<p>§20</p>
							</p>
							<p>
								W sytuacji powzięcia informacji o naruszeniu lub podejrzeniu naruszenia ochrony Danych Osobowych należy
								postępować zgodnie z zasadami wynikającymi z art. 33 i 34 RODO.
							</p>
							<p>
								Zgłoszenia naruszenia ochrony Danych Osobowych przez osobę, której dane dotyczą lub przez osobę trzecią
								są przyjmowane i rozpatrywane przez Administratora (członków zarządu Administratora).
							</p>
							<p>
								Szacowanie ryzyka dotyczące sytuacji naruszenia ochrony Danych Osobowych jest przeprowadzane
								Administratora (członków zarządu Administratora) lub inne upoważnione przez niego osoby.
							</p>
							<p className='text-center'>§21</p>
							<p>
								W sytuacji stwierdzenia wystąpienia naruszenia ochrony danych osobowych oraz prawdopodobieństwa
								zaistnienia ryzyka naruszenia praw lub wolności osób fizycznych, informacja o naruszeniu powinna zostać
								zgłoszona do PUODO, zgodnie z wymaganiami art. 33 RODO.
							</p>
							<p>Zgłoszenie przekazywane jest do PUODO zgodnie z trybem określonym przez ten organ.</p>
							<p className='text-center'>§22</p>
							<p>
								Administrator stosuje procedury pozwalające na identyfikację, ocenę i zgłoszenie zidentyfikowanego
								naruszenia ochrony Danych Osobowych do PUODO w terminie 72 godzin od ustalenia naruszenia
							</p>
							<p className='text-center'>Rozdział IX:</p>
							<p className='text-center'>
								Odpowiedzialność karna za naruszenie zasad ochrony danych
								<br />
								<p>§23</p>
							</p>
							<p>
								Naruszenie przepisów o ochronie danych osobowych jest zagrożone sankcjami karnymi, określonymi w art.
								107 – 108 UODO oraz w art. 130, 266 - 269, 287 ustawy z dnia 6 czerwca 1997 r. - Kodeks karny (t. j. Dz.
								U. z 2017 r., poz. 2204 z późn. zm.).
							</p>
							<p>
								Niezależnie od odpowiedzialności przewidzianej w przepisach, o których mowa w ust. 1, naruszenie zasad
								ochrony Danych Osobowych, może zostać uznane za ciężkie naruszenie podstawowych obowiązków pracowniczych
								i skutkować odpowiedzialnością na podstawie przepisów prawa pracy.
							</p>
							<p className='text-center'>Rozdział X:</p>
							<p className='text-center'>
								Postanowienia końcowe
								<br />
								<p>§24</p>
							</p>
							<p>
								Polityka jest dokumentem wewnętrznym i nie może być udostępniania osobom nieupoważnionym w żadnej
								formie.
							</p>
							<p>
								W sprawach nieuregulowanych w niniejszej Polityce mają zastosowanie przepisy RODO, UODO oraz inne
								przepisy prawa powszechnie obowiązującego.
							</p>
							<p>
								Pracownicy i współpracownicy Administratora zobowiązani są do bezwzględnego stosowania zasad określonych
								w Polityce.
							</p>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default Politic
