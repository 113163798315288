import Container from 'react-bootstrap/Container'
import certificate from '../../Img/certificate.svg'
import { Col, Row } from 'react-bootstrap'

function Describe() {
	return (
		<section className='describe_section' id='description'>
			<Container>
				<Row>
					<Col lg={6}>
						<div className='describe_secend_container'>
							<img className='describe_img' src={certificate} alt='certyfikat energetyczny' />
						</div>
					</Col>
					<Col xs={12} lg={6}>
						<div className='describe_main_text'>
							<h1 className='describe_section_header'>Czym są Świadectwa charakterystyki energetycznej?</h1>
							<p>
								Świadectwo charakterystyki energetycznej to dokument, który określa wielkość zapotrzebowania na energię
								niezbędną do zaspokojenia potrzeb energetycznych związanych z użytkowaniem budynku lub części budynku,
								czyli energii na potrzeby ogrzewania i wentylacji, przygotowania ciepłej wody użytkowej, chłodzenia, a w
								przypadku budynków niemieszkalnych również oświetlenia. Obowiązek posiadania w określonych sytuacjach
								świadectwa charakterystyki energetycznej budynku lub części budynku wynika z prawa europejskiego.
							</p>
							<h3>Zainteresowany?</h3>
							<button className='describe_button_buy'>
								<a className='describe_btn' href='/Cena'>
									Zamów teraz
								</a>
							</button>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	)
}

export default Describe
